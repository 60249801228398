var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.dark_overlay
      ? _c("div", { staticClass: "over-lay", on: { click: _vm.maskClose } })
      : _vm._e(),
    _c(
      "div",
      { staticClass: "relative" },
      [
        _c("vue-tags-input", {
          staticClass: "tag-field inline-block w-full",
          class: { "z-20": _vm.dark_overlay },
          attrs: {
            placeholder: "",
            tags: _vm.getSelectedTags(),
            "autocomplete-min-length": 0,
            "add-only-from-autocomplete": false,
            "autocomplete-items": _vm.tagList,
            "avoid-adding-duplicates": true,
            disabled: _vm.createingTag,
          },
          on: {
            "before-adding-tag": _vm.savedInputTag,
            "tags-changed": _vm.tagSelectionChanged,
            focus: function ($event) {
              return _vm.maskOpen()
            },
          },
          model: {
            value: _vm.tagPlaceholder,
            callback: function ($$v) {
              _vm.tagPlaceholder = $$v
            },
            expression: "tagPlaceholder",
          },
        }),
        _vm.diplayCreateButton
          ? _c(
              "button",
              {
                staticClass:
                  "absolute w-1/12 right-0 top-0 py-2 px-4 rounded font-bold bg-base-yellow",
                on: { click: _vm.submitTag },
              },
              [_vm._v(" 作成 ")]
            )
          : _vm.createingTag
          ? _c("div", {
              staticClass:
                "absolute h-8 w-8 loading-btn loader ease-linear rounded-full border-4 border-t-4 border-gray-400",
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }